<template>
  <div class="hourDetial">
    <div class="hourDetialtext">
      <div class="hourDetial-nav padding-0-20 dis-flex">
        <span>{{ daohang }}</span>
        <!--        <span class="padding-0-10">-</span>-->
        <!--        <span>科学系列</span>-->
        <!--        <span class="padding-0-10">-</span>-->
        <!--        <span>智慧农场</span>-->
        <!--        <span class="padding-0-10">-</span>-->
        <!--        <span>智慧农场</span>-->
      </div>
      <div class="hour-mu dis-flex padding-0-20 space-between">
        <div class="hour-mu-l dis-flex">
          <span>第{{ serial_nember }}课</span
          ><span class="margin-l-10">{{ titel }}</span>
        </div>
        <!---------------选项卡--------------->
        <div class="hour-mu-r dis-flex">
          <div
            v-for="(pro, index) in curriculum"
            :key="index"
            :class="isactive === index ? 'activeColor' : 'curriculumColor'"
            @click="switchover(pro.id, index)"
          >
            {{ pro.name }}
          </div>
        </div>
      </div>
      <!---------------选项卡切换内容------------------->
      <div class="switchover margin-auto-20">
        <!-----------课件-------------->
        <div v-if="show_tab == 1" class="courseware">
          <iframe
            id="iframe11"
            frameborder="no"
            scrolling="yes"
            style="margin: 0 auto"
            width="100%"
            height="600"
            allowfullscreen="allowfullscreen"
            :src="kejian_src"
          >
          </iframe>
        </div>
        <!-----------讲义-------------->
        <div v-if="show_tab == 2" class="handouts">
          <!--          <iframe id="iframe1"-->
          <!--                  style="margin: 0 auto"-->
          <!--                  width="100%" :height="height"-->
          <!--                  frameborder='no'-->
          <!--                  border='0'-->
          <!--                  marginwidth='0'-->
          <!--                  marginheight='0'-->
          <!--                  scrolling='no'-->
          <!--                  allowtransparency='yes'-->
          <!--                  :src="jiangyi_src"></iframe>-->
          <pdf
            ref="pdf"
            v-for="i in numPages"
            :key="i"
            :src="url"
            :page="i"
          ></pdf>
        </div>

        <!-----------说课视频-------------->
        <div v-if="show_tab == 3" class="speak">
          <!---------视频----------->
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsline="false"
            :options="playerOptions"
          ></video-player>
          <!--            <p>{{'视频' + i}}</p>-->
          <!---------视频描述----------->
          <div class="kong"></div>
        </div>
        <!-----------实录视频-------------->
        <div v-if="show_tab == 4" class="transcribe">
          <!---------视频----------->
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsline="false"
            :options="playerOptionss"
          ></video-player>
          <!--            <p>{{'视频' + i}}</p>-->
          <!---------视频描述----------->
          <div class="kong"></div>
        </div>
        <!-----------制作视频-------------->
        <div v-if="show_tab == 5" class="fabrication">
          <!---------视频----------->
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsline="false"
            :options="playerOptionsss"
          ></video-player>
          <!--            <p>{{'视频' + i}}</p>-->
          <!---------视频描述----------->
          <div class="kong"></div>
        </div>
        <div v-if="show_tab == 6" class="filecontinar">
          <el-table :data="file" style="width: 100%">
            <el-table-column label="名称" width="180" prop="title">
            </el-table-column>
            <el-table-column label="日期" width="180">
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">{{ scope.row.addtime }}</span>
              </template>
            </el-table-column>

            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type
                  @click="downloadFile(scope.row.file, scope.row.title)"
                  >下载</el-button
                >
                <!-- <a :href="scope.row.file" :download="scope.row.title">下载</a> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import pdf from "vue-pdf";
import api from "@/service/api/api";
import axios from "axios";

export default {
  name: "hourDetial",
  components: {
    pdf,
  },
  props: ["dochref", "doctype"],
  watch: {
    dochref(val) {
      console.log("pdfSrc");
      console.log(val);
      this.pdfSrc = val;
    },
    pdfSrc(val) {},
    doctype(typeval) {
      this.typeValue = typeval;
    },
  },
  data() {
    return {
      // 讲义
      typeValue: "",
      url: "",
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      numPages: 1,
      activeIndex: 0,
      serial_nember: "",
      titel: "",
      file: [],
      // 选项卡
      isactive: 0,
      show_tab: 1,
      curriculum: [
        { id: 1, name: "课件" },
        // {id: 2, name: '讲义'},
        // {id: 3, name: '说课视频'},
        // {id: 4, name: '实录视频'},
        // {id: 5, name: '制作视频'},
        // {id: 6, name: '附件'},
      ],
      // 选项卡切换内容
      courseware: true,
      handouts: false,
      speak: false,
      transcribe: false,
      fabrication: false,
      // 课件
      kejian_src: "",

      // 视频播放
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", // url地址
          },
        ],
        poster: "../../../../static/full_res.jpg", // 你的封面地址
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
      playerOptionss: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", // url地址
          },
        ],
        poster: "../../../../static/full_res.jpg", // 你的封面地址
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
      playerOptionsss: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", // url地址
          },
        ],
        poster: "../../../../static/full_res.jpg", // 你的封面地址
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
        daohang: "",
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.getNumPages()
  },

  methods: {
    // # 计算pdf页码总数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },
    // 初始化接口
    init() {
      let id = {
        id: this.$route.query.id,
      };

      api.kcdetail(id).then((res) => {
        if (res.errcode == 0) {
          this.daohang = res.data.daohang;
          this.serial_nember = res.data.serial_no;
          this.titel = res.data.title;
          this.kejian_src = res.data.ppt_url;

          if (res.data.word_url) {
            axios.get(res.data.word_url).then((e) => {
              this.curriculum.push({ id: 2, name: "讲义" });
            });
          }
          if (res.data.shuoke_video_url) {
            axios.get(res.data.shuoke_video_url).then((e) => {
              this.curriculum.push({ id: 3, name: "说课视频" });
            });
            // this.curriculum.push({ id: 3, name: "说课视频" });
          }
          if (res.data.shilu_video_url) {
            axios.get(res.data.shilu_video_url).then((e) => {
              this.curriculum.push({ id: 4, name: "实录视频" });
            });
          }
          if (res.data.zhizhuo_video_url) {
            axios.get(res.data.zhizhuo_video_url).then((e) => {
              this.curriculum.push({ id: 5, name: "制作视频" });
            });
          }
          if (res.data.file.length > 0) {
            this.curriculum.push({ id: 6, name: "附件" });
          }
          this.url = res.data.word_url;
          this.file = res.data.file;

          this.playerOptions.sources[0].src = res.data.shuoke_video_url;
          this.playerOptionss.sources[0].src = res.data.shilu_video_url;
          this.playerOptionsss.sources[0].src = res.data.zhizhuo_video_url;
          this.getNumPages();

          console.log("视频", this.playerOptions.sources[0].src);
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
      });
    },

    // 选项卡切换
    switchover(id, index) {
      this.show_tab = id;
      this.isactive = index;
    },

    downloadFile(url, fileName) {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function (e) {
        //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
        var url = window.URL.createObjectURL(x.response);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
  },
};
</script>

<style scoped>
* {
  touch-action: pan-y;
}

.hourDetial {
  width: 1200px;
  margin: 30px auto;
  min-height: 500px;
  background-color: #ffffff;
}

.hourDetialtext {
  width: 100%;
  min-height: 500px;
}

.hourDetial-nav {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}

.hour-mu {
  height: 60px;
  line-height: 50px;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
}

.hour-mu-l span {
  font-size: 20px;
  color: #555555;
  font-weight: 400;
}

/*选项卡*/
.curriculumColor {
  min-width: 50px;
  height: 18px;
  font-size: 18px;
  font-weight: 300;
  color: #555555;
  cursor: pointer;
  margin: 0 15px 0 0;
}

.activeColor {
  min-width: 50px;
  height: 18px;
  margin: 0 15px 0 0;
  font-size: 18px;
  font-weight: 300;
  color: #ff7800;
  cursor: pointer;
}

/*选项卡切换内容*/
.switchover {
  width: 100%;
  min-height: 500px;
}

/*视频播放*/
.video-js .vjs-icon-placeholder {
  width: 50%;
  height: 50%;
  display: block;
}

.video-player {
  width: 90%;
  margin: 0 auto;
}
</style>